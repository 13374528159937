import React, { useState, useEffect } from 'react';
import Navbar from "../navbar/navbar";
import './homepage.css';
import Image from "../my_image/image";
import Toggle from '../toggle/toggle';
import ProjectSlideshow from './ProjectSlideshow';
import { Helmet } from 'react-helmet';
import CanvasBackground from "./background/background";

// HomePage Component
const HomePage = () => {
    // State to manage theme
    const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');

    // Effect to apply theme and save preference
    useEffect(() => {
        console.log('Effect triggered. Dark mode:', isDarkMode);
        document.body.classList.toggle('dark', isDarkMode);
        document.body.classList.toggle('light', !isDarkMode);
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    // Toggle theme function
    const toggleTheme = () => {
        console.log('Toggling theme', !isDarkMode);
        setIsDarkMode(prevMode => !prevMode);
    };

    return (
        <>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-4T31PCY75R"></script>
                <script>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-4T31PCY75R');
                    `}
                </script>
            </Helmet>
            <Navbar />
            <Toggle onClick={toggleTheme} />
            <div className="big-div">
                <div className="home-page">
                    <h1 className="name-title">Kip Gorey</h1>
                    <h3 className="sub-name-title">Software - NLP - Robotics</h3>
                    <div>
                        <div className="container">
                            <p>
                                ○ USC Senior in Computer Science + Business
                                <br />
                                ○ I’m interested in automated decision and processes through NLP or multi-modal models.
                                <br />
                                ○ Want to learn more about Robotics and cyber-physical systems
                            </p>
                        </div>
                    </div>
                </div>

                <div className="project-timeline-section">
                    <div className="project-section">
                        <Image className="profile-image" />
                    </div>
                </div>

                <div className="projs">
                    <ProjectSlideshow />
                </div>
            </div>
        </>
    );
}

export default HomePage;

