import React from 'react';
import myImage from '../../assets/my_image2.png'
import './image.css'

const Image = () => {
    return (
        <div>
            <img src={myImage} alt="My Image" className="img" />
        </div>
    );
};

export default Image;
