import React, { useState, useEffect } from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';
import './toggle.css';

function Toggle() {
    const [theme, setTheme] = useState('light');

    useEffect(() => {
        document.body.className = theme;
        window.dispatchEvent(new Event('themeChange'));
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    return (
        <div className="toggle-container" onClick={toggleTheme}>
            {theme === 'light' ? <FaMoon className="icon" /> : <FaSun className="icon" />}
        </div>
    );
}

export default Toggle;

