import React from 'react';
import Navbar from "../navbar/navbar";
import './resume.css';
import PDFViewer from "../PDF/pdfViewer";
import CanvasBackground from "../homepage/background/background";

function Resume() {
    return (
        <>



            <div className="homepage">

                <Navbar/>


                <h1 className = "page-title"> Resume </h1>

                <a href="https://kip-gorey-resume.tiiny.site/" className="link">
                    <button className="button-77" role="button"> Go to Tiny Url Resume </button>
                </a>

            </div>

        </>
    );
}


export default Resume;