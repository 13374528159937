import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';
import ScrollLink from "./scrollLink";

const Navbar = () => {
    const [theme, setTheme] = useState('light');

    useEffect(() => {
        const handleThemeChange = () => {
            setTheme(document.body.className);
        };

        window.addEventListener('themeChange', handleThemeChange);

        return () => {
            window.removeEventListener('themeChange', handleThemeChange);
        };
    }, []);

    return (
        <nav className={`navbar ${theme}`}>
            <div className="navbar-content">
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <a href="https://github.com/kipgorey" target="_blank" rel="noopener noreferrer">Github</a>
                    </li>
                    <li>
                        <Link to="/Resume">Resume</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navbar;
