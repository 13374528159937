import React from 'react';
import './ProjectSlideshow.css';

// Import images for projects
import AmpariImage from '../../assets/ampari.png';
import GroupMeImage from '../../assets/group_me.png';

// Define projects with title, image, link
const projects = [
    {
        title: "Ampari",
        image: AmpariImage,
        link: "https://www.ampariai.com",
    },
    {
        title: "Group Me Extension",
        image: GroupMeImage,
        link: "https://fragrant-hospital-618.notion.site/USC-Group-Me-Application-fc9a2b265d13483b8310827dcb885fab?pvs=4",
    }
];

// Project component with title, clickable image
const Project = ({ title, image, link }) => (
    <div className="project">
        <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={image} alt={title} className="project-image" />
        </a>
        <h3 style={{ textDecoration: 'none', color: 'inherit' }}>{title}</h3>
    </div>
);

const ProjectSlideshow = () => {
    return (
        <div className="project-slideshow">
            <h1 className="projects-header">Projects</h1>
            <div className="project-grid">
                {projects.map((project, index) => (
                    <Project
                        key={index}
                        title={project.title}
                        image={project.image}
                        link={project.link}
                    />
                ))}
            </div>
        </div>
    );
};

export default ProjectSlideshow;
